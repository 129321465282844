document.body.onload = function() {
    var xhr = new XMLHttpRequest();
    var url = 'https://fonts.googleapis.com/css?family=Lato:300,300i,400,700&display=swap';

    xhr.open('GET', url, true);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            var style = document.createElement('style');
            style.innerHTML = xhr.responseText;
            document.head.appendChild(style);
        }
    };
    xhr.send();
}