import sass from './scss/styles.scss'

import ogimage from './images/bestory.jpg'
import phone1 from './images/phone-01.png'
import phone2 from './images/phone-02.png'
import phone3 from './images/phone-03.png'
import contenido from './images/contenido.jpg'
import contenido_desaturado from './images/contenido_desaturado.jpg'
import data_y_analytics from './images/data-y-analytics.jpg'
import data_y_analytics_desaturado from './images/data-y-analytics_desaturado.jpg'
import influencer from './images/influencer.jpg'
import influencer_desaturado from './images/influencer_desaturado.jpg'
import profesionalidad from './images/profesionalidad.png'
import transparencia from './images/transparencia.png'
import excelencia from './images/excelencia.png'
import rigor from './images/rigor.png'
import samsung from './images/samsung.png'
import santander from './images/santander.png'
import contamos from './images/contamos.jpg'
import yodona from './images/Yo-Dona.png'
import telva from './images/TELVA.png'
import metropoli from './images/Metropoli.png'
import marca from './images/Marca.png'
import fueradeserie from './images/Fuera-de-Serie.png'
import expansion from './images/Expansion.png'
import elmundo from './images/El-Mundo.png'
import cuidate from './images/Cuidate-Plus-Nuevo.png'
import actualidadaconomica from './images/Actualidad-Economica.png'
import logor from './images/logo-r.png'
import logorsvg from './images/logor.svg'
import favicon from './images/favicon.png'
import wible from './images/wible.png'
import disaronno from './images/disaronno.png'
import sensai from './images/sensai.png'
import yvesrocher from './images/yvesrocher.png'
import fonts from './js/font.js'
import owl from './js/owl.carousel.js'


//import great from './js/greatslider.js'

import lazyload from './js/lazy.js'
//import ui from './js/jquery.ui.js'
import main from './js/main.js'


import video from './videos/video.mp4'