(function() {
    var document = $(document);
    cuerpo = $('body'),
        ventana = $(window),
        widthVentana = ventana.width(),
        header = $('#header'),
        sec_somos = $('#somos'),
        sec_servicios = $('#servicios'),
        sec_caracteristicas = $('#caracteristicas'),
        caracteristica__list = $('#caracteristica__list'),
        sec_hemoscontado = $('#hemoscontado'),
        sec_contamostuhistoria = $('#contamoshistoria'),
        sec_unidadeditorial = $('#unidadeditorial'),
        date = new Date(),
        year = date.getFullYear(),
        output_year = $('#year'),
        animation_elements = $('.forload'),
        btn_hamburger = $('.btn_hamburger'),
        modal = $('.modal'),
        output_year.text(year);


    function lazy(wrapper) {
        wrapper.find('.lazy').lazy(200, function() {
            $(this).on('load', function() {
                $(this).addClass('loaded');
            });
        });
    }



    btn_hamburger.on('click', function(event) {
        event.preventDefault();
        var here = $(this);
        here.toggleClass('close');
        here.closest('header').find('nav').toggleClass('active');
    });


    modal.find('.btn-close').on('click', function(event) {
        var here = $(this);
        here.preventDefault;
        modal.removeClass('active');
        cuerpo.removeAttr('style');
    });


    sec_servicios.find('.servicios__item').hover(function() {
        $(this).addClass("active").siblings('li').removeClass('active');
    }, function() {
        //$(this).removeClass("active");
        //$('.servicios__item').eq(0).addClass('active');
    });


    if (screen.height < 769) {
        sec_somos.removeClass('animated');
    } else {
        sec_somos.addClass('animated');
    }


    function anchor(window_top_position) {
        $('body,html').animate({
            scrollTop: window_top_position
        }, 900);
        header.find('nav').removeClass('active');
        btn_hamburger.removeClass('close');
        header.addClass('fixed');
    }

    $('.nav__link--somos').on('click', function(event) {
        anchor(204);
        window.location.hash = '#que-hacemos';
    });

    $('.nav__link--servicios').on('click', function(event) {
        anchor(754);
    })

    $('.nav__link--caracteristicas').on('click', function(event) {
        if (screen.width < 768) {
            anchor(2100);
        } else {
            anchor(1519);
        }
    })

    $('.nav__link--contamos').on('click', function(event) {
        if (screen.width < 768) {
            anchor(2800);
        } else {
            anchor(2304);
        }
    })



    function check_if_in_view() {
        var window_height = ventana.height();
        var window_top_position = ventana.scrollTop();
        
        var window_bottom_position = (window_top_position + window_height) - 50;

        $.each(animation_elements, function() {
            var element = $(this);
            var element_height = element.outerHeight();
            var element_top_position = element.offset().top;
            var element_bottom_position = (element_top_position + element_height);
            if ((element_bottom_position >= window_top_position) && (element_top_position < window_bottom_position)) {
                element.addClass('loaded').removeClass('forload');

                if (header.hasClass('loaded')) {
                    lazy(header);
                }
                if (sec_somos.hasClass('loaded')) {
                    lazy(sec_somos);
                }
                if (sec_servicios.hasClass('loaded')) {
                    lazy(sec_servicios);
                    setTimeout(function() {
                        sec_servicios.addClass('animated');
                    }, 600)
                }

                if (sec_caracteristicas.hasClass('loaded')) {
                    if (caracteristica__list.length) {
                        caracteristica__list.owlCarousel({
                            items: 1,
                            loop: true,
                            smartSpeed: 700,
                            autoplay: true,
                            autoplayTimeout: 7480,
                            mouseDrag: true,
                            lazyLoad: true,
                            center: true,
                            startPosition: 0,
                            rewindNav: true,
                            responsive: {
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 1
                                },
                                1024: {
                                    items: 1
                                }
                            }
                        });
                    }
                }

                if (sec_hemoscontado.hasClass('loaded')) {
                    lazy(sec_hemoscontado);
                }
                if (sec_contamostuhistoria.hasClass('loaded')) {
                    var getUrl = sec_contamostuhistoria.find('.lazy').attr('data-src');
                    sec_contamostuhistoria.find('.lazy').attr('src', getUrl).addClass('loaded');
                }
                if (sec_unidadeditorial.hasClass('loaded')) {
                    lazy(sec_unidadeditorial);
                }

            } else {
                //$element.removeClass('loaded');
            }
        });
    }

    ventana.on('load scroll resize', check_if_in_view);
    ventana.trigger('scroll');

}());

$(window).scroll(function() {
    var top = $(this).scrollTop(),
        header = $('#header'),
        sec_somos = $('#somos');

    if (top > 140) {
        header.addClass("fixed");
        var getUrl = header.find('.logor').attr('data-src');
        header.find('.logor').attr('src', getUrl);
        sec_somos.css('margin-top', '220px');

    } else if (top < 140) {
        header.removeClass("fixed");
        sec_somos.removeAttr('style');
        sec_somos.addClass('animated');
    }
});


    $("video source").each(function() {
        var sourceFile = $(this).attr("data-src");
        $(this).attr("src", sourceFile);
        var video = this.parentElement;
        video.load();
        
        var playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                // Automatic playback started!
                // Show playing UI.
                video.play();
            })
            .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
            });
        }
    });